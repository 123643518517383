















































































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { namespace } from 'vuex-class'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import moment from 'moment'

import NotificationMixin from '@/mixins/notificationMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    DateInput,
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class AssociationAttachmentsCreate extends Mixins(NotificationMixin, PermissionsMixin, FlashMessageMixin, ValidatorConfigMixin) {
  @sessionModule.Getter state!: sessionState
  userAssociationId = ''

  form: Record<string, any> = {
    title: '',
    typeId: '',
    file: '',
    obtainedAt: moment().format('YYYY-MM-DD 12:00:00'),
    confirm: '',
    errors: {
      title: '',
      confirm: '',
      file: '',
      obtainedAt: ''
    }
  }

  selectedType: MyDocumentType = {
    category: '',
    description: '',
    id: '',
    name: '',
    score: 0
  }

  selectType (id: string) {
    this.selectedType = this.types.find((type: MyDocumentType) => type.id === id) || this.selectedType
  }

  types: DocumentTypes = []

  uploader: UploaderArray = []
  filesizeMB = 0

  acceptedFormats = ['.epub', '.doc', '.pdf', '.rtf', '.xls', '.xlsx', '.docx', '.ppt', '.pptx', '.gif', '.jpg', '.png', '.txt', '.csv']

  async onUpload ($e: any) {
    const filename = $e.target.files[0].name
    const fileExt = filename.split(/(?=\.)/g).slice(-1).pop().toLowerCase()
    const base64file = await this.toBase64($e.target.files[0]) as string

    this.filesizeMB = new Blob([base64file]).size / 10 ** 6
    const isAcceptedFormat = this.acceptedFormats.includes(fileExt)

    if (isAcceptedFormat && this.filesizeMB < 50) {
      this.uploader[0].state = 'complete'
      this.form.file = base64file
    } else {
      this.uploader[0].state = ''
      this.uploader[0].invalidMessage = isAcceptedFormat ? 'Arquivo excede 50 MB' : 'Formato não suportado'
      this.uploader[0].invalidMessageTitle = 'Erro'
    }
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  });

  done = true

  submit () {
    this.done = false

    if (this.filesizeMB >= 50) return

    axios.post(`user/association/${this.userAssociationId}/attachment`, snakeCaseKeys(this.form))
      .then(() => {
        const flashMessage = { message: 'Documentação enviada com sucesso.', isSuccess: true }
        this.$router.push({ name: 'AssociationIndex' }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  created () {
    this.userAssociationId = this.state.user.association.id

    axios.get('user/association/attachment/type')
      .then((response) => {
        this.types = response.data.data
      })
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":{name:'AssociationIndex'}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para Associação ")])],1),_c('div',{staticClass:"flex justify-center my-8"},[_c('ValidationObserver',{staticClass:"w-full px-3 lg:w-1/2 xl:w-1/3 list-mt-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl"},[_vm._v(" Enviar Documento ")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Título do Documento","invalid-message":errors[0]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"obtained at","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"label":"Obtido em","max-date":_vm.moment().format('YYYY-MM-DD'),"invalid-message":errors[0]},model:{value:(_vm.form.obtainedAt),callback:function ($$v) {_vm.$set(_vm.form, "obtainedAt", $$v)},expression:"form.obtainedAt"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{attrs:{"invalid-message":errors[0],"label":"Tipo do Documento","placeholder":"Selecione o tipo"},on:{"change":_vm.selectType},model:{value:(_vm.form.typeId),callback:function ($$v) {_vm.$set(_vm.form, "typeId", $$v)},expression:"form.typeId"}},_vm._l((_vm.types),function(type){return _c('cv-dropdown-item',{key:type.id,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)]}}],null,true)}),(_vm.selectedType.description)?_c('cv-inline-notification',{attrs:{"kind":"info","sub-title":_vm.selectedType.description,"low-contrast":true}}):_vm._e(),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"tag":"div","rules":"required","name":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-file-uploader',{attrs:{"accept":_vm.acceptedFormats,"clear-on-reselect":"","initial-state-uploading":"","removable":"","helper-text":"Upload"},on:{"input":_vm.onUpload},model:{value:(_vm.uploader),callback:function ($$v) {_vm.uploader=$$v},expression:"uploader"}},[_c('template',{slot:"drop-target"},[_vm._v(" Clique para fazer o upload ")])],2),(errors.length)?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione um arquivo ")]):_vm._e()]}}],null,true)}),_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),(_vm.notification.open)?_c('cv-inline-notification',{attrs:{"kind":_vm.notification.kind,"title":_vm.notification.title,"sub-title":_vm.notification.message,"close-aria-label":"Fechar","low-contrast":false},on:{"close":function($event){_vm.notification.open = false}}}):_vm._e(),_c('div',{staticClass:"mt-8"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_vm._v(" Enviar Documento ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }